/**
 * AUTOZIP CONTACT FORM
 */
const autoZip = () => {
  const btn1 = document.querySelector('.js-zip1');
  const btn2 = document.querySelector('.js-zip2');

  if (btn1) {
    btn1.addEventListener('click', function () {
      // @ts-ignore
      AjaxZip3.zip2addr('addr_zip', '', 'addr_prefecture', 'addr_address')
    })
  }

  if(btn2) {
    btn2.addEventListener('click', function () {
      // @ts-ignore
      AjaxZip3.zip2addr('client_zip', '', 'client_prefecture', 'client_address')
    })
  }
}

export default autoZip
