const pageToTop = () => {
  const pageTopBtn = document.querySelector('.js-btn-page-top');

  pageTopBtn?.addEventListener('click', () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  })

  window.addEventListener('scroll', () => {
    if (document.body.scrollTop > 250 || document.documentElement.scrollTop > 250) {
      pageTopBtn!.classList.add('visible')
    } else {
      pageTopBtn!.classList.remove('visible')
    }
  })
}

export default pageToTop