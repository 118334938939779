import pageToTop from './components/pageToTop'
import lazyload from './components/lazyload'
import sidemenuToggle from './components/sidemenuToggle'
import autoZip from './components/autoZip'
import toggleMegamenuItem from './components/toggleMegamenuItem'

document.addEventListener(
  'DOMContentLoaded',
  () => {
    pageToTop()
    lazyload()
    sidemenuToggle()
    autoZip()
    toggleMegamenuItem()
  },
  false
)
