/**
 * Toggle / Hide Megamenu
 * 
 */

const toggleMegamenuItem = () => {
  const items = document.querySelectorAll('.js-nav-item');
  const btns = document.querySelectorAll('.js-nav-btn');
  const megamenuLinks = document.querySelectorAll('.js-megamenu-link');

  // Hide megamenu when items other than .js-nav-btn and .js-megamenu are clicked
  document.querySelector('body')!.addEventListener('click', (e) => {
    if (e.target instanceof HTMLElement && !e.target.closest('.js-megamenu') && !e.target.className.includes('js-nav-btn')) {
      items.forEach( item => item.classList.remove('active'));
    }
  });

  // Hide megamenu when .js-curr-megamenu-link that is under .js-curr-nav-item is clicked. Note: .js-curr-page is added to a li.header__nav-item (ie href="./about") via PHP (header.php) when current file is accessed (ie /about or /about.php)
  if (megamenuLinks) {
    megamenuLinks.forEach(link => {
      link.addEventListener('click', (e) => {
        if (e.target instanceof HTMLElement && e.target.closest('.js-curr-nav-item')) {
          items.forEach( item => item.classList.remove('active'));
        }
      });
    });
  }

  // Toggle megamenu when .js-nav-btn is clicked
  if (btns) {
    btns.forEach(btn => {
      btn.addEventListener( 'click', () => {
        btn.closest('.js-nav-item')?.classList.toggle('active');
        btns.forEach(btn2 => {
          if ( btn != btn2 ) {
            btn2.closest('.js-nav-item')?.classList.remove('active');
          }
        });
      });
    });
  }
}

export default toggleMegamenuItem
