/**
 * Toggle sidemenu (header hamburger)
 * 
 */
const sidemenuToggle = () => {
  const hamburgerWrap = document.querySelector('.js-hamburger-wrap');
  const hamburger = document.querySelector('.js-hamburger');
  const sidemenu = document.querySelector('.js-sidemenu');
  const isScrollPage = document.querySelector('.js-scroll-page'); 

  // Toggle sidemenu on hamburger click
  hamburgerWrap?.addEventListener('click', () => {
    sidemenu?.classList.toggle('active');
    hamburger?.classList.toggle('active');
  });

  // Hide sidemenu when .js-sidemenu-link that is under .js-curr-sidemenu is clicked. Note: .js-curr-sidemenu is added to a div.sitemap__text-wrap (ie href="./building") via PHP (header.php) when current file is accessed (ie /building or /building.php)
  if ( isScrollPage ) {
    const links = document.querySelectorAll('.js-sidemenu-link');
    links?.forEach(link => {
      link.addEventListener('click', (e) => {
        if (e.target instanceof HTMLElement) {
          if (e.target.closest('.js-curr-sidemenu')) {
            sidemenu?.classList.toggle('active');
            hamburger?.classList.toggle('active');
          }
        }
      });
    });
  }

  // Hide sidemenu when items other than .js-sidemenu and .js-hamburger-wrap are clicked
  document.querySelector('body')!.addEventListener('click', (e) => {
    if (e.target instanceof HTMLElement) {
      if (!e.target.closest('.js-sidemenu') && !e.target.closest('.js-hamburger-wrap')) {
        sidemenu!.classList.remove('active');
        hamburger!.classList.remove('active');
      }
    }
  });
}

export default sidemenuToggle